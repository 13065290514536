<template>
  <div id="report-seguimiento">
    <v-container grid-list-xl fluid>
      <header-title title="Seguimiento"></header-title>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="initDateFormat"
                label="Fecha"
                readonly
                append-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fecha"
              no-title
              @input="menu1 = false"
              @change="getSeguimiento"
              locale="es"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <!-- <v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showStatistic">
            <v-icon>mdi-image-outline</v-icon>Gráfica
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="atendidos"
            :loading="loading"
            :search="search"
            no-data-text="No existen registros"
            no-results-text="Sin resultados"
            item-key="name"
            class="elevation-1"
            v-cloak
          >
            <template v-slot:item.tipo_tramite="{ item }">
              <td>
                {{ item.estado_id == 1 ? "" : item.tipotramite }}
              </td>
            </template>
            <template v-slot:item.tiempo="{ item }">
              <td>
                {{
                  item.tiempo.hours == undefined ? "0" : item.tiempo.hours
                }}:{{
                  item.tiempo.minutes == undefined ? "0" : item.tiempo.minutes
                }}:{{
                  item.tiempo.seconds == undefined ? "0" : item.tiempo.seconds
                }}
              </td>
            </template>
            <template v-slot:item.estado="{ item }">
              <td>
                <span v-if="item.estado_id == 1">
                  <v-chip small color="green lighten-4">atención</v-chip>
                </span>
                <span v-else-if="item.estado_id == 3">
                  <v-chip small color="blue lighten-4">finalizado</v-chip>
                </span>
                <span v-else>
                  <v-chip small color="orange lighten-4">proceso</v-chip>
                </span>
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="success"
                      @click="showDetail(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-view-list-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Mostrar detalle</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="mdialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Detalle de atención</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="teal--text">
              Contribuyente
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ atencion.contribuyente ? "S/N" : atencion.contribuyente }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              Teléfono: {{ atencion.fono ? atencion.fono : "s/t" }} Celular:
              {{ atencion.celular ? atencion.celular : "s/c" }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="teal--text">
              Hora de inicio y fin
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ atencion.hora_inicio }} - {{ atencion.hora_fin }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="teal--text">
              Fecha de atención
            </v-list-item-title>
            <v-list-item-subtitle>{{
              atencion.fecha_atencion | formatdate
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="teal--text py-0 my-0">
              Observaciones
            </v-list-item-title>
            <span class="grey--text text--darken-1">
              {{ atencion.observacion ? atencion.observacion : "s/o" }}
            </span>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="teal--text">Estado</v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="atencion.estado_id == 1"> En atención </span>
              <span v-else-if="atencion.estado_id == 3"> Finalizado </span>
              <span v-else> En proceso </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click.native="mdialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
      >{{ snack.text }}</v-snackbar
    >
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
export default {
  name: "report-seguimiento",
  components: {
    HeaderTitle,
  },
  data(vm) {
    return {
      search: "",
      loading: false,
      menu1: false,
      headers: [
        { text: "#", value: "id", align: "left", sortable: false },
        { text: "Operador ", value: "nombre_operador" },
        { text: "Tipo trámite ", value: "tipo_tramite" },
        { text: "Hora inicio ", value: "hora_inicio" },
        { text: "Hora fin ", value: "hora_fin" },
        { text: "Tiempo atención ", value: "tiempo" },
        { text: "Estado ", value: "estado" },
        { text: "Plataforma ", value: "plataforma" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      atencion: {},
      atendidos: [],
      usuario_id: "",
      role: 4,
      fecha: new Date().toISOString().substr(0, 10),
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      mdialog: false,
      snack: {
        state: false,
        color: "success",
        mode: "",
        timeout: 2500,
        text: "",
      },
    };
  },

  computed: {
    initDateFormat() {
      return this.fecha == "" ? "" : this.formatDate(this.fecha);
    },
  },
  mounted() {
    if (Service.getUser()) {
      this.role = Service.getUser().role;
      if (this.role != 1) {
        this.usuario_id = 0; //Service.getUser().uid;
        if (this.role == 4) {
          this.usuario_id = Service.getUser().uid;
        }
        this.getSeguimiento();
      } else {
        this.$router.replace({ name: "dashboard" });
      }
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getSeguimiento() {
      this.loading = true;
      this.atendidos = [];
      axios
        .post(
          Service.getBase() + "reporte/seguimiento",
          { fecha: this.fecha },
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.atendidos = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDetail(item) {
      this.atencion = item;
      this.mdialog = true;
    },
    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
